




































import { defineComponent, ref, computed } from '@vue/composition-api'
import GalleryModal from './GalleryModal.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default defineComponent({
  name: 'PhotoViewer',

  components: {
    GalleryModal,
    Swiper,
    SwiperSlide,
  },

  model: {
    prop: 'modelValue',
    event: 'update',
  },

  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    initialSlide: {
      type: Number,
      default: 0,
    },
  },

  emits: {
    update: null,
  },

  setup(props, context) {
    const swiper = ref<InstanceType<typeof Swiper> | null>(null)

    const swiperOptions = computed(() => ({
      pagination: {
        el: '.swiper-pagination',
        type: 'fraction',
      },
      initialSlide: props.initialSlide,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    }))

    function onUpdate(newValue: boolean) {
      context.emit('update', newValue)
    }

    const gallery = [
      {
        key: 'photo-1',
        imgUrl: '@/assets/images/gallery/1.png',
      },
      {
        key: 'photo-2',
        imgUrl: '@/assets/images/gallery/2.png',
      },
      {
        key: 'photo-3',
        imgUrl: '@/assets/images/gallery/3.png',
      },
      {
        key: 'photo-4',
        imgUrl: '@/assets/images/gallery/5.png',
      },
      {
        key: 'photo-5',
        imgUrl: '@/assets/images/gallery/6.png',
      },
      {
        key: 'photo-6',
        imgUrl: '@/assets/images/gallery/8.png',
      },
      {
        key: 'photo-7',
        imgUrl: '@/assets/images/gallery/9.png',
      },
      {
        key: 'photo-8',
        imgUrl: '@/assets/images/gallery/10.png',
      },
      {
        key: 'photo-9',
        imgUrl: '@/assets/images/gallery/13.png',
      },
      {
        key: 'photo-10',
        imgUrl: '@/assets/images/gallery/15.png',
      },
      {
        key: 'photo-11',
        imgUrl: '@/assets/images/gallery/16.png',
      },
    ]

    return {
      onUpdate,
      swiper,
      swiperOptions,
      gallery,
    }
  },
})
