






import { defineComponent } from '@vue/composition-api'
import Banner from '@/components/comoditiesComponents/Banner.vue'
import Content from '@/components/comoditiesComponents/Content.vue'

export default defineComponent({
  name: 'Gallery',

  components: {
    Banner,
    Content,
  },
})
