























































import { defineComponent, ref } from '@vue/composition-api'
import SiteSection from '@/components/SiteSection.vue'
import SiteShapeTree from '@/components/SiteShapeTree.vue'
import PhotoViewer from '../PhotoViewer.vue'

export default defineComponent({
  name: 'Content',
  components: {
    SiteSection,
    SiteShapeTree,
    PhotoViewer,
  },
  setup() {
    const isGalleryOpen = ref(false)

    const selectedIndex = ref(0)

    function toggleGalleryViewer(index: number): void {
      selectedIndex.value = index
      isGalleryOpen.value = !isGalleryOpen.value
    }

    const gallery = [
      {
        key: 'photo-1',
        imgUrl: '@/assets/images/gallery/1.png',
      },
      {
        key: 'photo-2',
        imgUrl: '@/assets/images/gallery/2.png',
      },
      {
        key: 'photo-3',
        imgUrl: '@/assets/images/gallery/3.png',
      },
      {
        key: 'photo-4',
        imgUrl: '@/assets/images/gallery/5.png',
      },
      {
        key: 'photo-5',
        imgUrl: '@/assets/images/gallery/6.png',
      },
      {
        key: 'photo-6',
        imgUrl: '@/assets/images/gallery/8.png',
      },
      {
        key: 'photo-7',
        imgUrl: '@/assets/images/gallery/9.png',
      },
      {
        key: 'photo-8',
        imgUrl: '@/assets/images/gallery/10.png',
      },
      {
        key: 'photo-9',
        imgUrl: '@/assets/images/gallery/13.png',
      },
      {
        key: 'photo-10',
        imgUrl: '@/assets/images/gallery/15.png',
      },
      {
        key: 'photo-11',
        imgUrl: '@/assets/images/gallery/16.png',
      },
    ]

    return {
      gallery,
      isGalleryOpen,
      toggleGalleryViewer,
      selectedIndex,
    }
  },
})
